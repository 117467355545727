import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { createRef, useContext, useEffect, useState } from "react";
import { AccessContext } from "../providers/AccessProvider";

export default function Toolbar(){

    const { apiUrl, shortcuts, editShortcuts } = useContext(AccessContext) 
    const [data, setData] = useState(null)
    const [realShortcuts, setRealShortcuts] = useState([])
    const [showHidden, setShowHidden] = useState(false)

    const loadShortcuts = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getShortcuts'
        })

        if(data.status === 1){
            setData(data.data)
        }else{
            setData([])
        }
    }

    useEffect(() => {
        loadShortcuts()
    }, [])

    useEffect(() => {

        if(shortcuts && data){
			const order = {}
			shortcuts.forEach(function (a, i) { order[a] = i; });
			data.sort(function (a, b) {
				return order[a.id] - order[b.id];
			});
			setRealShortcuts(data)
        }else{
            setRealShortcuts(data)
        }

    }, [data, shortcuts])

    useEffect(() => {
    }, [shortcuts])

	useEffect(() => {
		if(realShortcuts){
			let ids = []
			realShortcuts.map((item) => {
				ids.push(item.id)
			})

			if(ids.length > 0){
				editShortcuts(ids)
			}
		}
	}, [realShortcuts])

    function handleDrop(e) {
        e.stopPropagation();
      
        const dragIndex = parseInt(e.dataTransfer.getData('text/html'))
        const currentIndex = parseInt(e.target.id)
        
        let newShortcuts = realShortcuts
        const tmp = newShortcuts[currentIndex]
        newShortcuts[currentIndex] = realShortcuts[dragIndex]
        newShortcuts[dragIndex] = tmp
		
		setRealShortcuts([])
        setRealShortcuts(newShortcuts)
      
	}

    function handleDragStart(e) {
        
        e.target.style.opacity = '0.3';
        e.dataTransfer.effectAllowed = 'move';
        e.dataTransfer.setData('text/html', e.target.id)
	}
    
	function handleDragEnd(e) {
        e.target.style.opacity = '1';
	}
    
	function handleDragOver(e) {
        if (e.preventDefault) {
        	e.preventDefault();
        }
    
        return false;
	}
    
	function handleDragEnter(e) {
        e.target.classList.add('over');
	}
    
	function handleDragLeave(e) {
    	e.target.classList.remove('over');
	}



    const ActiveShortcut = ({pagetitle, url, introtext, icon, index}) => {
        
        const ref = createRef()

        useEffect(() => {
            if(ref.current){

                ref.current.addEventListener('dragstart', handleDragStart)
                ref.current.addEventListener('drop', handleDrop)
                ref.current.addEventListener('dragover', handleDragOver)
                ref.current.addEventListener('dragenter', handleDragEnter)
                ref.current.addEventListener('dragleave', handleDragLeave)
                ref.current.addEventListener('dragend', handleDragEnd)
            }
        }, [ref])
        
        return (
            <div className="col-3 col-xl-custom text-center text-xl-left dnd-item mb-3 mb-lg-4 icon-row-item" draggable="true" ref={ref} id={index} onClick={() => window.open(url)}>
                
                    <div className="row no-gutters justify-content-center align-items-xl-center py-xl-3">
                        <div className="col-auto col-xl-auto">
                            <div className="notification-icon-small px-xl-3">
                                <div className="square-icon-wrapper-small">
                                    {icon ? <FontAwesomeIcon icon={['fal', icon]} size="2x"  /> : null }
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl mt-2 mt-xl-0">
                            <h6>{pagetitle}</h6>
                            {introtext ? <p className="opacity-5 d-none d-xl-block pr-2">{introtext}</p> : null}
                        </div>
                    </div>
                
            </div>
        )
    }


    const InactiveShortcut = ({pagetitle, url, introtext, icon, index}) => {

        const ref = createRef()

        useEffect(() => {
            if(ref.current){
                ref.current.addEventListener('dragstart', handleDragStart)
                ref.current.addEventListener('drop', handleDrop)
                ref.current.addEventListener('dragover', handleDragOver)
                ref.current.addEventListener('dragenter', handleDragEnter)
                ref.current.addEventListener('dragleave', handleDragLeave)
                ref.current.addEventListener('dragend', handleDragEnd)
            }
        }, [ref])

        return (
            <div className="col-3 col-xl-custom text-center text-xl-left dnd-item icon-row-item  mb-3 mb-lg-4" draggable="true" ref={ref} id={index}  onClick={() => window.open(url)}>
                <a href={url} target="_blank">
                <div className="row no-gutters justify-content-center align-items-xl-center py-xl-3">
                    <div className="col-auto col-xl-auto">
                        <div className="notification-icon-small px-xl-3">
                            <div className="square-icon-wrapper-small">
                                <FontAwesomeIcon icon={['fal', icon]} size="2x"  />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl mt-2 mt-xl-0">
                        <h6>{pagetitle}</h6>
                        {introtext ? <p className="opacity-5 d-none d-xl-block pr-2">{introtext}</p> : null}
                    </div>
                </div>
                </a>
            </div>
        )
    }



    return (
        <section>
                    <div className="container py-3 py-lg-4">
                        <div className="row">
                            <div className="col-12 icon-row">
                                <div className="custom-shadow">
                                    <div className="row no-gutters py-3 py-xl-0 justify-content-xl-between dnd-container">
                                        

                                        {
                                            realShortcuts && realShortcuts.slice(0,3).map((item, key) => {
                                                return <ActiveShortcut key={key} {...item} index={key} />
                                            })
                                        }



                                        <div className="col-3 col-xl-custom text-center text-xl-left mb-3 mb-lg-4 icon-row-item ">
                                            <div className="row no-gutters justify-content-center align-items-xl-center py-xl-3" onClick={() => setShowHidden(!showHidden)}>
                                                <div className="col-auto col-xl-auto">
                                                    <div className="notification-icon-small px-xl-3">
                                                        <div className="square-icon-wrapper-small">
                                                            <FontAwesomeIcon icon={['fal', showHidden ? 'times' : 'bars']} size="2x"  />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl mt-2 mt-xl-0">
                                                    <h6 className="icon-row-toggler-text">Annet</h6>
                                                    <p className="opacity-5 d-none d-xl-block icon-row-toggler-para pr-2"> Øvrige tjenester</p>
                                                </div>
                                            </div>
                                        </div>
                                        { showHidden ? 
                                        <>
                                            {
                                                realShortcuts && realShortcuts.map((item, key) => {
													if(key >= 3){
														return <InactiveShortcut key={key} {...item} index={key} />
													}
                                                    
                                                })
                                            }
                                            </>
                                        : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
    )
}

function ToolbarItem(){

}