import Footer from "../components/Footer";
import ForestryManagerTop from "../components/ForestryManagerTop";
import Header from "../components/Header";
import InternalCards from "../components/InternalCards";
import NewsCards from "../components/NewsCards";
import NewsSwiper from "../components/NewsSwiper";
import Toolbar from "../components/Toolbar";
import Activities from "../components/Activities";

export default function Dashboard(){
    return (
        <>
            <Header statusMessage={true} />
            <div className="d-flex w-100 h-100 mx-auto flex-column">
    	        <main id="main" className="mb-auto">
                    <Toolbar/>
                    <NewsSwiper />
                    <Activities />
                    <NewsCards />
                    <ForestryManagerTop />
                    <InternalCards />
                    <Footer statusMessage={true} />
                </main>
            </div>
        </>
        
    )
}