import axios from "axios";
import { Buffer } from "buffer";
import { graphConfig } from "./authConfig";

/**
 * Attaches a given access token to a MS Graph API call. Returns information about the user
 * @param accessToken 
 */
export async function callMsGraph(accessToken) {
    const headers = new Headers();
    const bearer = `Bearer ${accessToken}`;

    headers.append("Authorization", bearer);

    const options = {
        method: "GET",
        headers: headers
    };

    return fetch('https://graph.microsoft.com/v1.0/me?$select=birthday,givenName,displayName,id,mobilePhone,OfficeLocation,surname,userPrincipalName', options)
        .then(response => response.json())
        .catch(error => console.log(error));
}

export async function callMsGraphPhoto(accessToken){
    

    const response = await axios(graphConfig.graphMeEndpoint + '/photo/$value', { headers: { Authorization: `Bearer ${accessToken}` }, responseType: 'arraybuffer' });
    const avatar = new Buffer(response.data, 'binary').toString('base64');

    return avatar
}
