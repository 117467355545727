import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from "axios";

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useState, useContext, useEffect } from 'react';
import {AccessContext} from '../providers/AccessProvider';
import Skeleton from './Skeleton';

export default function ForestryManagerTop(){

    const {darkmode, apiUrl, baseUrl} = useContext(AccessContext);

    const [podium, setPodium] = useState([])
    const [showPodium, setShowPodium] = useState(true)
    const [graphData, setGraphData] = useState(null)

    const [transportGraph, setTransportGraph] = useState(null)
    const [kontraheringGraph, setKontraheringGraph] = useState(null)
    const [produksjonGraph, setProduksjonGraph] = useState(null)

    const fetchGraphs = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getGraphs'
        })


        if(data.status === 1){
            setGraphData(data.data)
        }else{
            setGraphData(false)
        }
    }

    const fetchPeople = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getPodium'
        })

        setPodium(data)
    }

    useEffect(() => {

        if(graphData && graphData.transport && graphData.transport.graphData){
            const categories = graphData.transport.graphData[0].data.map((item) => {
                return item.x
            })

            const first = graphData.transport.graphData[0].data.map((item) => {
                return item.value
            })

            const second = graphData.transport.graphData[1].data.map((item) => {
                return item.value
            })

            setTransportGraph({
                note: graphData.transport.notabene,
                week: graphData.transport.weekNumber,
                content: [
                    graphData.transport.graphData[0],
                    graphData.transport.graphData[1]
                ],
                graphOptions: {
                    chart: {
                        type: 'column',
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: 'museo-sans',
                        }
                    },
                    title: {
                        text: ''
                    },
                    xAxis: {
                        categories: categories,
                        title: {
                            enabled: true,
                            text: 'mnd',
                            style: {
                                textTransform: 'uppercase',
                                opacity: '.5',
                                fontSize: '13px'
                            }
                        },
                        labels: {
                            style: {
                                textTransform: 'uppercase',
                                opacity: '.5',
                                fontSize: '13px'
                            }
                        }
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: ''
                        },
                        labels: {
                            style: {
                                textTransform: 'uppercase',
                                opacity: '.5',
                                fontSize: '13px'
                            }
                        } 
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                            '<td style="padding:0"><b>{point.y:.1f} m<sup>3</sup></b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.1,
                            borderWidth: 0
                        }
                    },
                    series: [{
                        name: graphData.transport.graphData[0].dname,
                        data: first,
                        color: darkmode ? "#FFFFFF" : '#051C26'
                    }, {
                        name: graphData.transport.graphData[1].dname,
                        data: second,
                        color: '#00B2C2'
            
                    }],
                    credits: {
                        enabled: false
                    },
                }})

            
        }

        if(graphData && graphData.kontrahering && graphData.kontrahering.graphData){
            const categories = graphData.kontrahering.graphData[0].data.map((item) => {
                return item.x
            })
            const values = graphData.kontrahering.graphData[0].data.map((item) => {
                return item.value
            })

            setKontraheringGraph({
                note: graphData.kontrahering.notabene,
                week: graphData.kontrahering.weekNumber,
                content: [
                    graphData.kontrahering.graphData[0]
                ],
                graphOptions: {
                    chart: {
                        type: 'areaspline',
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: 'museo-sans'
                        }
                    },
                    accessibility: {
                        description: 'Image description: An area chart compares the nuclear stockpiles of the USA and the USSR/Russia between 1945 and 2017. The number of nuclear weapons is plotted on the Y-axis and the years on the X-axis. The chart is interactive, and the year-on-year stockpile levels can be traced for each country. The US has a stockpile of 6 nuclear weapons at the dawn of the nuclear age in 1945. This number has gradually increased to 369 by 1950 when the USSR enters the arms race with 6 weapons. At this point, the US starts to rapidly build its stockpile culminating in 32,040 warheads by 1966 compared to the USSR’s 7,089. From this peak in 1966, the US stockpile gradually decreases as the USSR’s stockpile expands. By 1978 the USSR has closed the nuclear gap at 25,393. The USSR stockpile continues to grow until it reaches a peak of 45,000 in 1986 compared to the US arsenal of 24,401. From 1986, the nuclear stockpiles of both countries start to fall. By 2000, the numbers have fallen to 10,577 and 21,000 for the US and Russia, respectively. The decreases continue until 2017 at which point the US holds 4,018 weapons compared to Russia’s 4,500.'
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: categories,
                        allowDecimals: false,
                        title: {
                            enabled: true,
                            text: 'mnd',
                            style:{
                                textTransform: 'uppercase',
                                opacity: '1',
                                fontSize: '13px',
                                color: '#000000'
                            }
                        },
                        labels: {
                            formatter: function () {
                                return this.value; // clean, unformatted number for year
                            },
                            style: {
                                color: '#000000',
                                textTransform: 'uppercase',
                                fontSize: '13px'
                            },
                        },
                        lineColor: 'transparent',
                        tickColor: 'transparent'
                    },
                    yAxis: {
                        title: {
                            text: ''
                        },
                        labels: {
                            formatter: function () {
                                return this.value / 1000 + 'k';
                            },
                            style: {
                                color: '#000000',
                                textTransform: 'uppercase',
                                fontSize: '13px'
                            },
                        },
                        gridLineColor: 'transparent'
                    },
                    tooltip: {
                        pointFormat: 'Kontrahert <b>{point.y:,.0f}</b>'
                    },
                    legend: {
                        enabled: false,
                    },
                    plotOptions: {
                        area: {
                            marker: {
                                enabled: false,
                                symbol: 'circle',
                                radius: 2,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        },
                    },
                    series: [{
                        name: 'Skogledertoppen',
                        data: values,
                        fillColor: {
                            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1},
                            stops: [
                                [0, 'rgba(242,145,0,1)'],
                                [1, 'rgba(242,145,0,0)']
                            ]
                        },
                        color: '#FFFFFF'
                    }],
                    credits: {
                        enabled: false
                    },
                }
            })
        }

        if(graphData && graphData.produksjon && graphData.produksjon.graphData){
            const categories = graphData.produksjon.graphData[0].data.map((item) => {
                return item.x
            })
            const values = graphData.produksjon.graphData[0].data.map((item) => {
                return item.value
            })

            setProduksjonGraph({
                note: graphData.produksjon.notabene,
                week: graphData.produksjon.weekNumber,
                content: [
                    graphData.produksjon.graphData[0]
                ],
                graphOptions: {
                    chart: {
                        type: 'spline',
                        backgroundColor: 'transparent',
                        style: {
                            fontFamily: 'museo-sans',
                        }
                    },
                    title: {
                        text: ''
                    },
                    subtitle: {
                        text: ''
                    },
                    xAxis: {
                        categories: categories,
                        title: {
                            enabled: true,
                            text: 'mnd',
                            style: {
                                textTransform: 'uppercase',
                                opacity: '.5',
                                fontSize: '13px'
                            }
                        },
                        labels: {
                            style: {
                                textTransform: 'uppercase',
                                opacity: '.5',
                                fontSize: '13px'
                            }
                        },
                        maxPadding: 0.05,
                        showLastLabel: true,
                        lineColor: 'transparent',
                        tickColor: 'transparent',
                        
                    },
                    yAxis: {
                        title: {
                            text: ''
                        },
                        labels: {
                            formatter: function () {
                                return this.value / 1000 + 'k';
                            },
                            style: {
                                textTransform: 'uppercase',
                                opacity: '.5',
                                fontSize: '13px'
                            }
                        },
                        
                        lineColor: 'transparent',
                        lineWidth: 2,
                        gridLineColor: 'transparent'
                    },
                    legend: {
                        enabled: false
                    },
                    tooltip: {
                        headerFormat: '<b>{series.name}</b><br/>',
                        pointFormat: '{point.x}: {point.y}',
                        style: {
                            color: '#000000'
                        }
                    },
                    plotOptions: {
                        spline: {
                            marker: {
                                enable: false,
                                symbol: 'circle',
                                radius: 0,
                                states: {
                                    hover: {
                                        enabled: true
                                    }
                                }
                            }
                        }
                    },
                    series: [{
                        name: 'Produksjon',
                        data: values,
                        color: '#78BF21'
                    }],
                    credits: {
                        enabled: false
                    },
                }
            })
        }
    }, [graphData, darkmode])

    useEffect (() => {
        fetchGraphs();
        fetchPeople();
    }, [])

    useEffect(() => {
        
    }, [transportGraph])


    const PodiumPerson = ({ index, profile_img, profile_firstname, profile_lastname, number }) => {
        return (
            <div className={"mt-4 number " + (index === 0 ? 'col-6 order-1' : 'col-3') + (index === 1 ? '  order-0' : '  order-2')}>
                <div className="mx-auto text-center">
                    <div className="img-wrapper-profile position-relative mb-4">
                        { index === 0 ? <img src={process.env.PUBLIC_URL + "/gfx/krone3.svg"} className="crown-profile" /> : null }
                        
                        <img src={baseUrl + profile_img} className="rounded-circle white-border" />
                        <span className="position">#{index + 1}</span>
                    </div>
                    <div>
                        <span className="d-block">{profile_firstname} {profile_lastname}</span>
                        <span className="fs-3"><strong>{number} m<sup>3</sup></strong></span>
                    </div>
                </div>
            </div>
        )
    }

    const NotPodiumPerson = ({ index, profile_img, profile_firstname, profile_lastname, number }) => {
        return (

            <div className="col-6 number">
                <div className="d-lg-flex flex-wrap align-items-center mb-2">
                    <div className="img-wrapper-profile position-relative mx-auto mx-lg-0">
                        <img src={baseUrl + profile_img} className="rounded-circle white-border" />
                        <span className="position">#{index+1}</span>
                    </div>
                    <div className="pl-lg-4">
                        <span className="d-block small">{profile_firstname} {profile_lastname}</span>
                        <span className="fs-2"><strong>{number} m<sup>3</sup></strong></span>
                    </div>
                </div>
            </div>
        )
    }


    if(graphData === null) return <Loading />
    if(graphData === false) return <h3>Fant ingenting! :((</h3>

    return (
        <section>
            { kontraheringGraph ? 
                <div id="statistics-complicated" className="my-4">
                    <div className="container">
                        <div className="bg-sand overflow-hidden rounded shadow">
                            <div className="row no-gutters py-3">
                                <div className="col-lg-12">
                                    <div className="px-3 py-4">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={kontraheringGraph.graphOptions}
                                        />
                                    </div>
                                </div>
                                {
                                    /*

                                    
                                <div className="col-lg-6">

                                    { showPodium ? 
                                        (
                                        <div id="pallen" className="py-4 px-3 position-relative">
                                            <figure className="cover position-absolute mb-0 opacity-25">
                                                <img src={process.env.PUBLIC_URL + "/gfx/fireworks.svg"} className="w-100 h-100" />
                                            </figure>
                                            <div className="row">
                                                <div className="col">
                                                    <h2>Skogbruksleder&shy;toppen</h2>
                                                </div>
                                                <div className="col-auto">
                                                    <button className="btn btn-secondary toggle-pallen" onClick={() => setShowPodium(false)} >Se flere <FontAwesomeIcon icon={['fal', 'arrow-down']} className="pl-2" /></button>
                                                </div>
                                            </div>
                                            
                                            <div className="row no-gutters mt-4 align-items-end px-3">
                                                { 
                                                    podium && podium.map((item, key) => {
                                                        if(key > 2) return
                                                        return <PodiumPerson key={key} {...item} index={key} />
                                                    })
                                                }
                                                
                                            </div>
                                        </div>
                                        )
                                        :
                                        (
                                        <div id="ranking" className="py-4 px-3">
                                            <div className="row">
                                                <div className="col">
                                                    <h2>Skogbruksleder&shy;toppen</h2>
                                                </div>
                                                <div className="col-auto">
                                                    <button className="btn btn-secondary toggle-pallen" onClick={() => setShowPodium(true)} >Pallen <FontAwesomeIcon icon={['fal', 'arrow-up']} className="pl-2" /></button>
                                                </div>
                                            </div>
                                            <div className="row no-gutters mt-4 text-center text-lg-left">
                                                { 
                                                    podium && podium.map((item, key) => {
                                                        if(key < 3) return
                                                        return <NotPodiumPerson key={key} {...item} index={key} />
                                                    })
                                                }
                                            </div>
                                        </div>
                                        )
                                    }
                                </div>
                                */
                            }
                            </div>
                            
                            <div className="bg-white-gradient">
                                <div className="row">
                                
                                    <div className="col-12 col-lg-6 py-3">
                                        <div className="d-flex no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="notification-icon-small px-3">
                                                    <div className="bg-orange text-white square-icon-wrapper-small">
                                                    <FontAwesomeIcon icon={['fal', 'handshake']} size="2x"/>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <p className="mb-0 opacity-5">Kontrahering uke {kontraheringGraph.week}</p>
                                                <h6 className="mb-0">{kontraheringGraph.content[0].totalvalue} m<sup>3</sup> <span className={"pl-2" + (kontraheringGraph.content[0].incdec === 'inc' ? ' text-success' : ' text-danger')}><FontAwesomeIcon icon={['fal', kontraheringGraph.content[0].incdec === 'inc' ? 'arrow-up' : 'arrow-down']} /> {kontraheringGraph.content[0].percent}%</span></h6>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white">
                                <div className="row">
                                    <div className="col-12 py-3">
                                        <div className="d-flex no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="notification-icon-small px-3">
                                                    <div className="bg-white square-icon-wrapper-small">
                                                    <FontAwesomeIcon icon={['fal', 'circle-question']} size="2x" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <p className="mb-0">{kontraheringGraph.note}</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            : null}

            
            <div className="container">
                <div className="row">

                    { produksjonGraph ? 
                        <div className="col-lg-6 mb-3">
                            <div className="bg-white shadow rounded overflow-hidden">
                                <div className="px-3 py-4">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={produksjonGraph.graphOptions}
                                    />
                                </div>
                                <div className="bg-success-opacity py-3">
                                    <div className="d-flex no-gutters align-items-center">
                                        <div className="col-auto">
                                            <div className="notification-icon-small px-3">
                                                <div className="bg-celebrate text-white square-icon-wrapper-small">
                                                    <FontAwesomeIcon icon={['fal', 'trees']} size="2x" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <p className="mb-0 opacity-5 fs-1">Produksjon uke {produksjonGraph.week}</p>
                                            <h6 className="mb-0">{produksjonGraph.content[0].totalvalue} m<sup>3</sup> <span className={"pl-2" + (produksjonGraph.content[0].incdec === 'inc' ? ' text-success' : ' text-danger')}><FontAwesomeIcon icon={['fal', produksjonGraph.content[0].incdec === 'inc' ? 'arrow-up' : 'arrow-down']} /> {produksjonGraph.content[0].percent}%</span></h6>
                                        </div>
                                    </div>
                                            
                                </div>
                                <div className="bg-white py-3">
                                    <div className="d-flex no-gutters align-items-center">
                                        <div className="col-auto">
                                            <div className="notification-icon-small px-3">
                                                <div className="bg-white square-icon-wrapper-small">
                                                    <FontAwesomeIcon icon={['fal', 'circle-question']} size="2x" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <p className="mb-0 fs-1">{produksjonGraph.note}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    : null}

                    { transportGraph ? 
                        <div className="col-lg-6 mb-3">
                            <div className="bg-white shadow rounded overflow-hidden">
                                <div className="px-3 py-4">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={transportGraph.graphOptions}
                                    />
                                </div>
                                <div className="bg-blue-opacity py-3">
                                    <div className="d-flex no-gutters align-items-center">
                                        <div className="col-auto">
                                            <div className="notification-icon-small px-3">
                                                <div className="bg-blue-gradient text-white square-icon-wrapper-small">
                                                    <FontAwesomeIcon icon={['fal', 'tractor']}  size="2x"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <p className="mb-0 opacity-5 fs-1">Transport uke {transportGraph.week}</p>
                                            <h6 className="mb-0">
                                                {transportGraph.content[0].dname}: {transportGraph.content[0].totalvalue} m<sup>3</sup> <span className={"pl-2" + (transportGraph.content[0].incdec === 'inc' ? ' text-success' : ' text-danger')}><FontAwesomeIcon icon={['fal', transportGraph.content[0].incdec === 'inc' ? 'arrow-up' : 'arrow-down']} /> {transportGraph.content[0].percent}%</span> 
                                                <span className="text-blue pl-2">{transportGraph.content[1].dname}: {transportGraph.content[1].totalvalue} m<sup>3</sup></span> <span className={"pl-2" + (transportGraph.content[1].incdec === 'inc' ? ' text-success' : ' text-danger')}><FontAwesomeIcon icon={['fal', transportGraph.content[1].incdec === 'inc' ? 'arrow-up' : 'arrow-down']} /> {transportGraph.content[1].percent}%</span> 
                                            </h6>
                                        </div>
                                    </div>
                                </div>
                                {transportGraph.note && transportGraph.note.length > 0 ? 
                                    <div className="bg-white py-3">
                                        <div className="d-flex no-gutters align-items-center">
                                            <div className="col-auto">
                                                <div className="notification-icon-small px-3">
                                                    <div className="bg-white square-icon-wrapper-small">
                                                    <FontAwesomeIcon icon={['fal', 'circle-question']} size="2x" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <p className="mb-0 fs-1">{transportGraph.note}</p>
                                            </div>
                                        </div>
                                    </div>
                                : null }
                            </div>
                        </div>
                    : null }
                </div>
            </div>
        </section>
    )
}

function Loading(){
    return (
        <section>
            <div id="statistics-complicated" className="my-4">
                <div className="container">
                    <div className="bg-sand overflow-hidden rounded shadow">
                        <div className="row no-gutters py-3">
                            <div className="col-lg-6">
                                <div className="px-3 py-4 h-100">
                                <div className="d-flex h-100 justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={['fad', 'spinner-third']} size="4x" spin />
                                </div>
                                </div>
                            </div>
                            <div className="col-lg-6">

                                
                                    <div id="pallen" className="py-4 px-3 position-relative">
                                        <figure className="cover position-absolute mb-0 opacity-25">
                                            <img src={process.env.PUBLIC_URL + "/gfx/fireworks.svg"} className="w-100 h-100" />
                                        </figure>
                                        <div className="row">
                                            <div className="col">
                                                <Skeleton width={300} height={40} />
                                            </div>
                                        </div>
                                        
                                        <div className="row no-gutters mt-4 align-items-end px-3">
                                            <div className="col-6 mt-4 number order-1">
                                                <div className="mx-auto text-center">
                                                    <div className="img-wrapper-profile position-relative mb-4">
                                                        <img src={process.env.PUBLIC_URL + "/gfx/krone3.svg"} className="crown-profile" />
                                                        <FontAwesomeIcon icon={['fad', 'user']} size="4x" />
                                                        <span className="position">#1</span>
                                                    </div>
                                                    <div>
                                                        <span className="d-block"><Skeleton width={80} height={20} /></span>
                                                        <span className="fs-3"><Skeleton width={100} height={30} /></span>
                                                    </div>
                                                </div>
                                            </div>
                                        
                                            <div className="col-3 number order-0 ">
                                                <div className="mx-auto text-center">
                                                    <div className="img-wrapper-profile position-relative mb-4">
                                                    <FontAwesomeIcon icon={['fad', 'user']} size="4x" />
                                                        <span className="position">#2</span>
                                                    </div>
                                                    <div>
                                                    <span className="d-block"><Skeleton width={80} height={20} /></span>
                                                        <span className="fs-3"><Skeleton width={100} height={30} /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-3 number order-2">
                                                <div className="mx-auto text-center">
                                                    <div className="img-wrapper-profile position-relative mb-4">
                                                        <FontAwesomeIcon icon={['fad', 'user']} size="4x" />
                                                        <span className="position">#3</span>
                                                    </div>
                                                    <div>
                                                    <span className="d-block"><Skeleton width={80} height={20} /></span>
                                                        <span className="fs-3"><Skeleton width={100} height={30} /></span>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                            </div>
                        </div>
                        
                        <div className="bg-white-gradient">
                            <div className="row">
                            
                                <div className="col-12 col-lg-6 py-3">
                                    <div className="d-flex no-gutters align-items-center">
                                        <div className="col-auto">
                                            <div className="notification-icon-small px-3">
                                                <div className="bg-orange text-white square-icon-wrapper-small">
                                                    <FontAwesomeIcon icon={['fal', 'handshake']} size="2x"/>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <Skeleton width={200} height={15} />
                                            <h6 className="mb-0"><Skeleton width={100} height={20} /> <span className="text-success pl-2"><FontAwesomeIcon icon={['fal', 'arrow-up']} /> <Skeleton width={30} height={20} /></span></h6>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="bg-white">
                            <div className="row">
                                <div className="col-12 py-3">
                                    <div className="d-flex no-gutters align-items-center">
                                        <div className="col-auto">
                                            <div className="notification-icon-small px-3">
                                                <div className="bg-white square-icon-wrapper-small">
                                                <FontAwesomeIcon icon={['fal', 'circle-question']} size="2x" />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col">
                                            <Skeleton width={300} height={20} />
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container">
                <div className="row">
                    <div className="col-lg-6 mb-3">
                        <div className="bg-white shadow rounded overflow-hidden">
                            <div className="px-3 py-5 my-5">
                                <div className="d-flex h-100 justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={['fal', 'spinner-third']} size="4x" spin />
                                </div>
                            </div>
                            <div className="bg-success-opacity py-3">
                                <div className="d-flex no-gutters align-items-center">
                                    <div className="col-auto">
                                        <div className="notification-icon-small px-3">
                                            <div className="bg-celebrate text-white square-icon-wrapper-small">
                                                <FontAwesomeIcon icon={['fal', 'trees']} size="2x" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <Skeleton width={200} height={15} />
                                        <h6 className="mb-0"><Skeleton width={100} height={20} /> <span className="text-success pl-2"><FontAwesomeIcon icon={['fal', 'arrow-up']} /> <Skeleton width={30} height={20} /></span></h6>
                                    </div>
                                </div>
                                        
                            </div>
                            <div className="bg-white py-3">
                                <div className="d-flex no-gutters align-items-center">
                                    <div className="col-auto">
                                        <div className="notification-icon-small px-3">
                                            <div className="bg-white square-icon-wrapper-small">
                                                <FontAwesomeIcon icon={['fal', 'circle-question']} size="2x" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                        <Skeleton width={300} height={20} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-3">
                        <div className="bg-white shadow rounded overflow-hidden">
                            <div className="px-3 py-5 my-5">
                                <div className="d-flex h-100 justify-content-center align-items-center">
                                    <FontAwesomeIcon icon={['fal', 'spinner-third']} size="4x" spin />
                                </div>
                            </div>
                            <div className="bg-blue-opacity py-3">
                                <div className="d-flex no-gutters align-items-center">
                                    <div className="col-auto">
                                        <div className="notification-icon-small px-3">
                                            <div className="bg-blue-gradient text-white square-icon-wrapper-small">
                                            <FontAwesomeIcon icon={['fal', 'tractor']}  size="2x"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                    <Skeleton width={200} height={15} />
                                            <h6 className="mb-0"><Skeleton width={100} height={20} /> <span className="text-success pl-2"><FontAwesomeIcon icon={['fal', 'arrow-up']} /> <Skeleton width={30} height={20} /></span></h6>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-white py-3">
                                <div className="d-flex no-gutters align-items-center">
                                    <div className="col-auto">
                                        <div className="notification-icon-small px-3">
                                            <div className="bg-white square-icon-wrapper-small">
                                            <FontAwesomeIcon icon={['fal', 'circle-question']} size="2x" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col">
                                    <Skeleton width={300} height={20} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}