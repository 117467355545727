import { Swiper, SwiperSlide, useSwiper  } from 'swiper/react';
import { Pagination, Thumbs, Virtual } from 'swiper';
import 'swiper/css'
import 'swiper/css/pagination';
import { createRef, useContext, useEffect, useState } from 'react';
import axios from 'axios'
import { AccessContext } from '../providers/AccessProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useNavigate } from 'react-router-dom';
import ReactDOMServer from 'react-dom/server';
import moment from 'moment'
import { useRef } from 'react';

export default function NewsSwiper(){

    const [mainSwiper, setMainSwiper] = useState(null)
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [articles, setArticles] = useState(null)

    const [activeSlide, setActiveSlide] = useState(0)
    
    const paginationRef = createRef()
    const swiperRef = createRef()
    const { baseUrl, apiUrl } = useContext(AccessContext)

    const changeSlide = (index) => {
        mainSwiper.slideTo(index)
    }

    const fetchArticles = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getLatestArticles',
            limit: 5,
            offset: 0
        })

        if(data.status === 1){
            setArticles(data.data)
        }else{
            setArticles([])
        }

        
    }

    const ArticleSlide = ({ image, category, pagetitle, alias, longtitle }) => {

        const { label, icon, value, colorClass } = category
        return (
            <>
            <Link to={`/artikler/${alias}`}>
                <figure className="position-relative mb-0 gradient-overlay rounded">
                    { image ? <img src={baseUrl + image} className="rounded w-100" alt="#" /> : <img src={process.env.PUBLIC_URL + '/images/cropped-swiper-img.jpg'} className="rounded" alt="#" /> }
                    
                </figure>
                <div className="position-absolute" style={{top: 0, left: 0, right: 0, bottom: 0}}>
                    <div className="px-4 h-100">
                        <div className="d-flex no-gutters h-100">
                            
                                <div className="col-12 col-lg-9 mt-auto mb-4">
                                
                                    <p className="text-uppercase mb-0 text-white">{label}</p>
                                    <span className="slide-heading text-white">{longtitle ? longtitle : pagetitle}</span>
                                    
                                </div>
                            
                        </div>
                    </div>
                </div>
                </Link>
            </>
        )
    }

    const ThumbSlide = ({ index, image, category, pagetitle, className, thumbnail, dates, alias }) => {
        
        const { label, icon, value, colorClass } = category
        const navigate = useNavigate()
        const date = moment(dates ? dates.from : null)
        const ref = useRef()
        
        const [active, setActive] = useState(activeSlide === index)

        useEffect(() => {
            setActive(activeSlide === index)
        }, [activeSlide])

        const goToArticle = () => {
            if(active) navigate(`artikler/${alias}`)
        }


        return (
                
                <div ref={ref} onMouseEnter={() => changeSlide(index)} onClick={() => goToArticle()} className={"d-flex no-gutters align-items-center py-1  " + className} data-sideswiper-item={index}>
                    <div className="col-auto pr-3">
                    
                        { category && category.value === 'event' && date._isValid ? 
                            <div className="   ml-0 text-center img-wrapper">
                                <div className="date-wrapper  bg-light mr-2 h-100 w-100" style={{borderRadius: 0}}>
                                <p className="small mb-0 text-uppercase">{date.format('MMM')}</p>
                                <p className="my-0 font-weight-bold">{date.format('DD')}</p>
                                <p className="my-0 text-muted small">{date.format('ddd')}</p>
                                </div>
                            </div>
                        : (
                            <div className="img-wrapper">
                                {thumbnail ? 
                                <img src={baseUrl + thumbnail} className="h-100 w-100" alt="#" style={{objectFit: 'cover'}} /> :
                                <div className={"square-icon-wrapper " + colorClass}>
                                    {category && category.icon ? <FontAwesomeIcon icon={['fal', category.icon]}  size="lg" className={colorClass === 'bg-light' ? 'text-dark' : 'text-white'}  /> : <FontAwesomeIcon icon={['fal', 'file']}  size="lg" className={colorClass === 'bg-light' ? 'text-dark' : 'text-white'}  /> }
            
                                </div>
                                }
                            
                    </div>
                        )}
                    </div>
                    <div className="col">
                        <p className="text-muted mb-0 small">{label}</p>
                        <p className="font-weight-bold mb-0 sidebar-title">{pagetitle}</p>
                    </div>
                </div>
            
        )
    }


    useEffect(() => {
        fetchArticles()
    }, [])

    if(!articles){
        return (<div></div>)
    }

    return (
        <section>
            <div className="container py-3 py-lg-4">
                <div className="row align-items-center ">
                    <div className="main-swiper col-12 col-lg-9 ">
                        <Swiper
                            speed={400}
                            spaceBetween={100}
                            ref={swiperRef}
                            thumbs={{ swiper: thumbsSwiper }}
                            onSwiper={setMainSwiper}
                            modules={[Thumbs]}
                            className='row align-items-center'
                            onSlideChange={(e) => setTimeout(() => setActiveSlide(e.activeIndex), 500)}
                        >
                            {
                                articles && articles.map((slideContent, index) => {
                                    return (
                                        <SwiperSlide key={index} virtualIndex={index}>
                                            <ArticleSlide index={index} {...slideContent} />
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                        
                    </div>
                    <div className='side-swiper col-12 col-lg py-4 position-relative mt-4 mt-lg-0'>
                        <div className="bg-white shadow p-3 rounded">
                            <div className="swiper-pag">
                                <Swiper
                                    modules={[Thumbs]}
                                    watchSlidesProgress
                                    onSwiper={setThumbsSwiper}
                                    slidesPerView={2}
                                    allowTouchMove={false}
                                    loop={false}
                                    freeMode={false}
                                    slideToClickedSlide={false}
                                    watchOverflow
                                    watchSlidesVisibility
                                    direction='vertical'
                                >
                                    {
                                        articles && articles.map((slideContent, index) => {
                                            return (
                                                <SwiperSlide key={index} virtualIndex={index}>
                                                    <ThumbSlide index={index} {...slideContent} />
                                                </SwiperSlide>
                                            )
                                        })
                                    }
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )

    return (
        <section>
            <div className="container py-3 py-lg-4">
                <div className="row align-items-center ">
                    <div className="main-swiper col-12 col-lg-9 ">
                        <Swiper
                            speed={400}
                            spaceBetween={100}
                            ref={swiperRef}
                            modules={[Pagination, Thumbs, Virtual]}
                            virtual
                            className='row align-items-center'
                            pagination={{ 
                                clickable: true, 
                                el: '.swiper-pag',
                                renderBullet: (index, className) => {
                                    //return '<span class="' + className + '">' + (index + 1) + '</span>';
                                    if(!articles[index]) return <div></div>
                                    if(articles[index]){
                                        return ReactDOMServer.renderToStaticMarkup( <ThumbSlide className={className} key={index} index={index} {...articles[index]} />);
                                    }
                                }
                            }}
                        >
                            {
                                articles && articles.map((slideContent, index) => {
                                    return (
                                        <SwiperSlide key={index} virtualIndex={index}>
                                            <ArticleSlide {...slideContent} />
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                    </div>
                    <div className='side-swiper col-12 col-lg py-4 position-relative mt-4 mt-lg-0'>
                        <div className="bg-white shadow p-3 rounded">
                            <div className="swiper-pag"></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
    
    return (
        <section>
            <div className="container py-3 py-lg-4">
                <Swiper
                    speed={400}
                    spaceBetween={100}
                    ref={swiperRef}
                    modules={[Pagination, Thumbs, Virtual]}
                    virtual
                    className='row align-items-center'
                    pagination={{ 
                        clickable: true, 
                        el: '.swiper-pag',
                        renderBullet: (index, className) => {
                            //return '<span class="' + className + '">' + (index + 1) + '</span>';
                            if(!articles[index]) return false
                            if(articles[index]){
                                return ReactDOMServer.renderToStaticMarkup( <ThumbSlide className={className} key={index} index={index} {...articles[index]} onClick={() => alert('lol')}/>);
                            }
                        }
                    }}
                >
                    
                    {
                        articles && articles.map((slideContent, index) => {
                            return (
                                <SwiperSlide key={index} virtualIndex={index}>
                                    <ArticleSlide {...slideContent} />
                                </SwiperSlide>
                            )
                        })
                    }
                    
                    <div slot="container-end" className='side-swiper col-12 col-lg py-4 position-relative mt-4 mt-lg-0'>
                        <div className="bg-white shadow p-3 rounded">
                            <div className="swiper-pag"></div>
                        </div>
                    </div>
                </Swiper>
            </div>
        </section>
    )
    

    return (
        <section>
            <div className="container py-3 py-lg-4">
                <div className="row align-items-center ">
                    <div className="main-swiper col-12 col-lg-9 ">
                        <Swiper
                            speed={400}
                            spaceBetween={100}
                            ref={swiperRef}
                            onSlideChange={(e) => {
                                const allItems = document.querySelectorAll('[data-sideswiper-item]')
                                allItems.forEach(element => {
                                    element.classList.remove('active')
                                })
                                const item = document.querySelector('[data-sideswiper-item="'+e.activeIndex+'"]')
                                console.log(item.classList.add('active'))
                            }}
                            onSwiper={(swiper) => console.log(swiper)}
                            modules={[Pagination, Thumbs, Virtual]}
                            pagination={{el: paginationRef}}
                            thumbs={{ swiper: thumbsSwiper }}
                            virtual
                        >
                            {
                                articles && articles.map((slideContent, index) => {
                                    return (
                                        <SwiperSlide key={index} virtualIndex={index}>
                                            <ArticleSlide {...slideContent} />
                                        </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                        
                    </div>
                    <div className="side-swiper col-12 col-lg py-4 position-relative mt-4 mt-lg-0">
                        <div className="bg-white shadow p-3 rounded">
                            <Swiper
                                modules={[Thumbs]}
                                watchSlidesProgress
                                onSwiper={setThumbsSwiper}
                                slidesPerView={2}
                                spaceBetween={10}
                                loop={false}
                                freeMode={false}
                                slideToClickedSlide={true}
                                centeredSlides
                                centeredSlidesBounds
                                watchOverflow
                                watchSlidesVisibility
                                direction='vertical'
                                
                            >
                                {
                                    articles && articles.map((slideContent, index) => {
                                        return (
                                            <SwiperSlide key={index} virtualIndex={index}>
                                                <ThumbSlide {...slideContent} />
                                            </SwiperSlide>
                                        )
                                    })
                                }
                            </Swiper>
                        </div>
                            <div className="swiper-pagination" ref={paginationRef}></div>
                    </div>
                </div>
            </div>
        </section>
    )  
}