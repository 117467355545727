import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { dateFormat } from "highcharts"
import moment from "moment"
import { useContext, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Footer from "../components/Footer"
import Header from "../components/Header"
import { AccessContext } from "../providers/AccessProvider"

export default function Article(){

    const { apiUrl } = useContext(AccessContext)

    const params = useParams()

    const [data, setData] = useState(null)

    const getArticle = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getArticle',
            alias: params.alias
        })
        
        if(data.status === 1){
            setData(data.data)
        }else{
            setData({})
        }
        
    }

    useEffect(() => {
        if(params.alias){
            getArticle()
        }
    }, [])

    useEffect(() => {
    }, [data])

    //Laster artikkelen
    if(!data) return (
        <>
            <Header/>
            <Loading />
            <Footer />
        </>
    )

    //Fant ikke artikkelen
    if(Object.keys(data).length === 0) return (
        <>
            <Header/>
            <NotFound />
            <Footer />
        </>
    )

    //Artikkelinnhold
    
    return (
        <>
            <Header />
            <ArticleContent data={data} />
            <Footer />
        </>
    )
}

function ArticleContent({data}){
    const { baseUrl } = useContext(AccessContext)
    const { pagetitle, introtext, content, editedon, createdon, author, category, img_list } = data
    
    const date = moment(data.dates.from)
    const toDate = moment(data.dates.to)

    const formatDate = () => {
        const now = moment()
        const date = moment(editedon ? editedon : createdon)
       
        const diff = now.diff(date, 'days')
       
        if(diff === 0) return 'i dag'
        if(diff === 1) return `i går`
        if(diff > 1) return `for ${diff} dager siden` 
    }
    
    const fixZeroHour = (date) => {
        // Hvis timer og minutter er 00:00 så skal ikke klokkeslettet vises. Ref Thomas.
        let result = '';
        if(!date.isValid()) return result;
        
        if (date.format('HH:mm:ss') === '00:00:00') result = date.format('DD.MM.YYYY');
        else result = date.format('DD.MM.YYYY HH:mm');
        
        return result;
    }

    return (
        <div className="d-flex w-100 h-100 mx-auto flex-column">
            
    	    <article id="main" className="mb-auto">
                <div className="container wide mb-4">
                    <div className="row">
                        <div className="col-12">
                            <img src={baseUrl + img_list} className="w-100 rounded mb-4" alt="Alt-tekst" />
                        </div>
                        <div className="col-xl-8 offset-xl-2">
                            <div className="article-meta">
                                <div className="row no-gutters">
                                    
                                    { category && category.value === 'event' && date._isValid ? 
                                        <div className="col-auto pr-3">
                                            <div className="date-wrapper text-center bg-light mr-2 ml-0">
                                                <p className="small mb-0 text-uppercase">{date.format('MMM')}</p>
                                                <p className="my-0 font-weight-bold">{date.format('DD')}</p>
                                                <p className="my-0 text-muted small">{date.format('ddd')}</p>
                                            </div>
                                        </div>
                                    : null}
                                    <div className="col">
                                        <div className="row no-gutters">
                                            <div className="col-auto">
                                                {category ? <span className="fs-1 text-uppercase">{category.label}</span> : null}
                                            </div>
                                            {
                                                category && category.value === 'event' && date._isValid ? 
                                                <>
                                                <div className="col-auto">
                                                    <span className="fs-1 text-uppercase pl-3">{fixZeroHour(date)} {toDate.isValid() ? `til ${fixZeroHour(toDate)}` : ''}</span>
                                                </div>
                                                </>
                                                : null 
                                            }
                                        </div>
                                        
                                        <h1>{pagetitle}</h1>
                                    </div>
                                </div>
                                
                                

                                { author ? 
                                <div className="d-flex align-items-center author my-4">
                                    { author.photo ? 
                                        <img src={baseUrl + author.photo} alt={author.name} className="author-image" /> :     
                                
                                        <div className="notification-icon-small mr-2 ml-0">
                                            <div className={"text-white square-icon-wrapper-small bg-celebrate"}>
                                                <FontAwesomeIcon icon={['fal', 'user']} size="2x"  />
                                            </div>
                                        </div>
                                    }
                                    <div className="pl-3">
                                        <strong className="fs-1">{author.name}</strong>
                                        <p className="mb-0 fs-1">Oppdatert {formatDate()}</p>
                                    </div>
                                </div>
                                : null }

                                { introtext ? <p className="introtext">{introtext}</p> : null }
                            </div>
                            <div className="rte" dangerouslySetInnerHTML={{__html: content}}></div>
                        </div>
                        
                    </div>
                </div>
                
            </article>

        </div>
    )
}

function NotFound(){
    return (
        <h1>404</h1>
    )
}

function Loading(){
    return (
        <div className="main" style={{height: '100vh', overflow: 'hidden'}}>
            <div className="row align-items-center justify-content-center h-100 flex-column">
                <div className="col-auto">
                    <FontAwesomeIcon size="4x" icon={['fad', 'spinner-third']} spin className="mb-3" />
                 </div>
                 <div className="col-auto">
                    <h3>Vent litt..</h3>
                 </div>
            </div>
        </div>
    )
}