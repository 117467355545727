import { useMsal } from "@azure/msal-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { createContext, useEffect, useState } from "react";
import { loginRequest } from "../authConfig";
import { callMsGraph, callMsGraphPhoto } from "../graph";

export const AccessContext = createContext({
    user: null,
    image: null,
    accessToken: null,
    shortcuts: null,
    baseUrl: null,
    apiUrl: null,
    debug: () => {},
    editShortcuts: () => {},
    toggleDarkMode: () => {}
})

export const AccessProvider = ({children}) => {

    const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;

    const [dataFetched, setDataFetched] = useState(false)
    const [user, setUser] = useState(null)
    const [shortcuts, setShortcuts] = useState(null)
    const [darkmode, setDarkmode] = useState(defaultDark)
    const [image, setImage] = useState(null)
    const [accessToken, setAccessToken] = useState(null)
    const [baseUrl, setBaseUrl] = useState('https://intranett.viken.skog.no/')
    const [apiUrl, setApiUrl] = useState(baseUrl + 'api') // live: 'api', dev: 'api-dev'


    const { instance, accounts } = useMsal();

    useEffect(() => {
        getAccessToken()
    }, [])

    useEffect(() => {
        if(darkmode){
            document.querySelector('meta[name="theme-color"]').setAttribute("content", '#051C26');
            document.querySelector('body').classList.add('darkmode')
        }else{
            document.querySelector('meta[name="theme-color"]').setAttribute("content", '#FFF');
            document.querySelector('body').classList.remove('darkmode')
        }
        
    }, [darkmode])

    useEffect(() => {
        getUserData()
    }, [accessToken])

    const getAccessToken = async () => {
        instance.acquireTokenSilent({
            ...loginRequest,
            account: accounts[0]
        }).then((response) => {
            setAccessToken(response.accessToken)
        })
    }

    const getUserData = () => {
        if(accessToken){
            callMsGraph(accessToken).then(response => setUser(response));
            callMsGraphPhoto(accessToken).then(response => setImage('data:image/*;base64, ' + response))
        }
    }

    const loadUserData = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getAzureUser',
            ...user
        })
        
        if(data.status === 1){
            setShortcuts(data.data.shortcuts)
            setDarkmode(data.data.darkmode)
        }
        setDataFetched(true)
    }

    const updateUserDB = async () => {
        if(user && dataFetched){
            const { data } = await axios.post(apiUrl, {
                action: 'int-updateAzureUser',
                darkmode: darkmode,
                shortcuts: shortcuts,
                image: image,
                ...user
            })

            //console.log('Updating user', data)
        }
    }

    useEffect(() => {
        updateUserDB()
    }, [darkmode, shortcuts])

    useEffect(() => {
        if(user){
            loadUserData()
        }
    }, [user])


    return <AccessContext.Provider value={{
        user,
        image,
        accessToken,
        baseUrl,
        apiUrl,
        shortcuts,
        darkmode,
        debug: () => {
            updateUserDB()
        },
        editShortcuts: (data) => {
            //console.log('Edit shortcuts', data)
            setShortcuts(data)
        },
        toggleDarkMode: () => {
            setDarkmode(!darkmode)
        }
    }}>
        { false ? <Loading /> : 
        <div className={"main " + (darkmode ? "darkmode" : "")}>
            {children}
        </div>
        }
    </AccessContext.Provider>
}

function Loading(){
    return (
        <div className="main" style={{height: '100vh', overflow: 'hidden'}}>
            <div className="row align-items-center justify-content-center h-100 flex-column">
                <div className="col-auto">
                    <FontAwesomeIcon size="4x" icon={['fad', 'spinner-third']} spin className="mb-3" />
                 </div>
                 <div className="col-auto">
                    <h3>Vent litt..</h3>
                 </div>
            </div>
        </div>
    )
}