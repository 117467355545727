import React, { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";
import { PageLayout } from "./components/PageLayout";
import "./styles/App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Dashboard from "./pages/Dashboard";
import { AccessProvider } from "./providers/AccessProvider";
import Article from "./pages/Article";
import 'moment/locale/nb.js'
import moment from 'moment'
import { useEffect } from "react";

/**
 * If a user is authenticated the ProfileContent component above is rendered. Otherwise a message indicating a user is not authenticated is rendered.
 */
const MainContent = () => {   
    return (
        <div className="App">
            <AuthenticatedTemplate>
                <AccessProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" element={<Dashboard />} />
                            <Route path="/intranett" element={<Dashboard />} />
                            <Route path="/catch" element={<Dashboard />} />
                            <Route path="/artikler/:alias" element={<Article/>}/>
                            <Route path="/catch/artikler/:alias" element={<Article/>}/>
                            <Route path="/intranett/artikler/:alias" element={<Article/>}/>
                        </Routes>
                    </BrowserRouter>
                </AccessProvider>
            </AuthenticatedTemplate>

            <UnauthenticatedTemplate>
                <LoginScreen />
            </UnauthenticatedTemplate>
        </div>
    );
};

const LoginScreen = () => {
    const { instance } = useMsal();

    const handleLogin = (loginType) => {
        instance.loginRedirect(loginRequest).catch(e => {
            //console.log(e);
        });
        /*
        if (loginType === "popup") {
            instance.loginPopup(loginRequest).catch(e => {
                console.log(e);
            });
        } else if (loginType === "redirect") {
            instance.loginRedirect(loginRequest).catch(e => {
                console.log(e);
            });
        }
        */
    }

    useEffect(() => {
        handleLogin()
    })

    return (
        <div className="main" style={{height: '100vh',backgroundSize: 'cover', overflow: 'hidden'}}>
            <div className="row align-items-center justify-content-center h-100">
                <div className="col-auto">
                    <div className="icon-row-item p-5" style={{backgroundColor: '#FFF'}}>
                        <div className="row align-items-center no-gutters mb-4">
                            <div className="col-auto">
                                <img src={process.env.PUBLIC_URL + "/gfx/tre-test.svg"} alt="logo" className="pr-3" />
                            </div>
                            <div className="col">
                                <h3 className="mb-0">Viken Skog</h3>
                                <h5 className="m-0">Intranett</h5>
                            </div>
                        </div>
                        
                        
                        <button className="btn btn-success w-100" onClick={() => handleLogin()}>Logg inn</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default function App() {
    return <MainContent />
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
