import React from 'react';
import { createRef, useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment'
import { AddToCalendarButton } from 'add-to-calendar-button-react';


const AddToCalendar = (props) => {  

    let dateFrom = moment(props.dateFrom).format('YYYY-MM-DD');
    let dateTo = props.dateTo;
    let timeFrom = moment(props.dateFrom).format('HH:mm');
    let timeTo;
    if(!dateTo) {
        dateTo = dateFrom;
        timeTo = timeFrom;
    } else {
        dateTo = moment(props.dateTo).format('YYYY-MM-DD');
        timeTo = moment(props.dateTo).format('HH:mm');
    }    

    return(
        <div className="add-to-calendar-button-wrap">
            <AddToCalendarButton                
                name={props.description}             
                startDate={dateFrom}
                endDate={dateTo}
                startTime={timeFrom}
                endTime={timeTo}
                timeZone="Europe/Oslo"
                location="World Wide Web"
                options="'Apple','Google','iCal','Outlook.com','Yahoo','MicrosoftTeams','Microsoft365'"
                listStyle="dropup-static"
                customCss="https://www.viken.skog.no/assets/int/css/customButton.css"
                buttonStyle="custom"
                hideBackground
                size="3"
                label="Legg i kalender"
                language="no"
                >
                    
            </AddToCalendarButton>
        
      
    </div>
      )
}

export default AddToCalendar;