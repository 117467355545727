import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

export default function Footer({ statusMessage }){
    return (
        <footer className="bg-white py-5">
            <div className="container">
                <div className="row justify-content-between align-items-center">
                    <div className="col-auto">&copy; Viken Skog</div>
                    <div className="col-auto">
                        {!statusMessage ? <p className="mb-0 fs-2 homelink"><Link to="/"><FontAwesomeIcon icon={['fal', 'arrow-left-long']} className="pl-2" /> <strong>Hjem</strong></Link></p> : null}
                    </div>
                    <div className="col-auto"><a href="https://www.viken.skog.no/" target="_blank">Gå til nettsted</a></div>
                </div>
            </div>
        </footer>
    )
}