import React from 'react';
import { createRef, useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/react';
import { Scrollbar, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import ActivitySlide from './ActivitySlide';
import mixitup from 'mixitup';
import mixitupMultifilter from '../vendor/mixitup-multifilter';


mixitup.use(mixitupMultifilter);


const ActivitiesSwiper = ({ slides, dataLoaded }) => {

    const swiperRef = useRef(null);
    const mixerRef = useRef(null);

    
    const updateSwiper = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            setTimeout(() => {
                swiperRef.current.swiper.update();
                const allCards = document.querySelectorAll('.activities .swiper-slide.item');
                const visibleCards = Array.from(allCards).filter(card => getComputedStyle(card).display !== 'none');
                visibleCards.forEach((showCard, index) => {                    
                    if (index === 0) {
                        showCard.classList.add('item-show'); // Add class to the first element that is not display none
                    } else {
                        showCard.classList.remove('item-show'); // Remove class from other elements
                    }
                });
                
            }, 100);
        }
    }

    useEffect(() => {    
        if (dataLoaded) {
            mixitup(mixerRef.current, {
                selectors: {
                    target: ".item",
                },
                animation: {
                    duration: 500
                },
                multifilter: {
                    enable: true
                }
            });

            mixerRef.current.addEventListener('mixEnd', () => {
                updateSwiper();
            });            
        }
      }, [dataLoaded]);     

    useEffect(() => {        
 
        const allSlides = document.querySelectorAll('.activities .swiper-slide');
        allSlides.forEach((slide, index) => {
            // Set z-index in reverse orderI need to 
            slide.style.zIndex = allSlides.length - index;
            setTimeout(() => {
                if(index === 0) {                
                    slide.classList.add('item-show');
                }   
            }, 0);         
        });

        const firstSlide = document.querySelector('.activities .swiper-slide:first-child .card');
                           
        const handleMouseOver = () => {
            firstSlide.classList.add('js-first-slide');
        };

        const handleMouseOut = () => {
            firstSlide.classList.remove('js-first-slide');
        };

        const allCards = document.querySelectorAll('.activities .swiper-slide:not(:first-child) .card');
        
        allCards.forEach((card, index) => {
            card.addEventListener('mouseover', handleMouseOver);
            card.addEventListener('mouseout', handleMouseOut);                    
        });
    }, [slides]);

    


    


    return (

        <div className="activities">              
            <div className="items" ref={mixerRef}>
                <Swiper

                    ref={swiperRef}
                    spaceBetween={20}
                    slidesPerView='auto'
                    freeMode={true}
                    modules={[Scrollbar, FreeMode]}
                    scrollbar={{ draggable: true }}
                    preventClicks= {false}
                    preventClicksPropagation={false}
                    breakpoints={{                        
                        1199: {                          
                          spaceBetween: -180,
                        }
                      }}
                >
                    
                    {slides.map((slide, index) => (

                            <SwiperSlide key={slide.id}
                            className={`item ${slide.category.label ? slide.category.label : ''}`}
                            data-search={slide.pagetitle.toLowerCase()}>
                                <ActivitySlide filter={slide.category.label} title={slide.category.label} 
                                description={slide.pagetitle} image={slide.image} alias={slide.alias} url={slide.url} 
                                externalUrl={slide.external_url} dateFrom={slide.dates.from} dateTo={slide.dates.to} />
                            </SwiperSlide>
                                                                                            
                    ))}

                </Swiper>
            </div>  
        </div>
                    
               
   )
}

export default ActivitiesSwiper;