import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AccessContext } from "../providers/AccessProvider";
import moment from 'moment'

export default function NewsCards(){


    const [articles, setArticles] = useState(null)
    const [articlesAvailable, setArticlesAvailable] = useState(true)


    const { baseUrl, apiUrl, darkmode } = useContext(AccessContext);

    
    const loadMore = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getLatestArticles',
            limit: 4,
            offset: articles.length + 5 // Endret denne fra + 4 til + 5, da kom ikke samme sak opp igjen to ganger.
        })

        if(data.status === 1){
            if(data.data.length < 4) setArticlesAvailable(false)
            setArticles([...articles, ...data.data])
        }
    }

    useEffect(() => {
    }, [articles])
  
    const fetchArticles = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getLatestArticles',
            limit: 4,
            offset: 5
        })

        

        if(data.status === 1){
            setArticles(data.data)
        }else{
            setArticles([])
        }
    }

    useEffect(() => {
        fetchArticles()
    }, [])

    const Article = ({ id, alias, pagetitle, category, image, dates, thumbnail }) => {
        const date = moment(dates ? dates.from : null)

        return (
            <div className="col-12 col-lg-6 py-3">
                <div className="card">
                    <Link to={`/artikler/${alias}`}>
                        <img className="card-img-top object-fit-cover w-100" src={baseUrl + image} alt="#" />
                        <div className="card-body shadow">
                            <div className="row no-gutters align-items-center">
                                <div className="col-auto text-center">
                                    { category && category.value === 'event' && date._isValid ? 
                                    <div className="date-wrapper bg-light mr-2 ml-0">
                                        <p className="small mb-0 text-uppercase">{date.format('MMM')}</p>
                                        <p className="my-0 font-weight-bold">{date.format('DD')}</p>
                                        <p className="my-0 text-muted small">{date.format('ddd')}</p>
                                    </div>
                                    : 
                                        <div className="notification-icon-small mr-2 ml-0">
                                            <div className={"text-white square-icon-wrapper-small " + (category && category.colorClass ? category.colorClass : 'bg-celebrate')}>
                                                {
                                                    thumbnail ? <img src={baseUrl + thumbnail} className="h-100 w-100 img-wrapper" alt="#" style={{objectFit: 'cover'}} /> : (
                                                        <>
                                                            {category && category.icon ? <FontAwesomeIcon icon={['fal', category.icon]} size="2x"  /> : <FontAwesomeIcon icon={['fal', 'file']} size="2x"  /> }
                                                        </>
                                                    )
                                                }
                                                
                                            </div>
                                        </div>
                                    }
                                </div>
                                <div className="col">
                                    <p className="text-muted small mb-0">{category && category.label}</p>
                                    <h6>{pagetitle}</h6>
                                </div>
                            </div>
                        </div>
                        </Link>
                </div>
            </div>
        )
    }


    return (
        <section>
            <div className="container py-3 py-lg-4">
                <div className="row">
                    {
                        articles && articles.map((item, key) => {
                            return <Article key={key} {...item} />
                        })
                    }
                    {articlesAvailable ? 
                    <div className="col-12 text-right">
                        <a href="#" className={"small btn-link " + (darkmode ? "text-white" : "text-dark")}  onClick={(e) => {
                            e.preventDefault()
                            loadMore()
                        }}>Hent flere internsaker</a>
                    </div> : null }
                </div>
            </div>
        </section>
    )
}