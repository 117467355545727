import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createRef, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { AccessContext } from '../providers/AccessProvider'
import moment from 'moment'
import axios from 'axios'
import Search from './Search'
moment.locale("nb")
export default function Header({ statusMessage }){
    const { user, image, debug, toggleDarkMode, apiUrl, baseUrl, darkmode } = useContext(AccessContext)
    const [showSearch, setShowSearch] = useState(false)
    const [showNotifications, setShowNotifications] = useState(false);
    const [showRedDot, setShowRedDot] = useState(false);
    const [status, setStatus] = useState("")
    const searchBtnRef = createRef()

    useEffect(() => {
            const birthdaysLocal = localStorage.getItem('viken_skog.int.hiddenBirthdays')
            const notificationLocal = localStorage.getItem('viken_skog.int.hiddenNotifications')
            if(birthdaysLocal) setHiddenBirthdays(JSON.parse(birthdaysLocal))
            if(notificationLocal) setHiddenNotifications(JSON.parse(notificationLocal))
    }, [])

    const [hiddenNotifications, setHiddenNotifications] = useState([])
    const [hiddenBirthdays, setHiddenBirthdays] = useState([])

    const [notifications, setNotifications] = useState([])
    const [birthdays, setBirthdays] = useState([])

    useEffect(() => {
        setShowNotifications(false)
        if(hiddenBirthdays.length !== birthdays.length) setShowNotifications(true)
        if(hiddenNotifications.length !== notifications.length) setShowNotifications(true)
    }, [birthdays, hiddenBirthdays, notifications, hiddenNotifications])
    
    useEffect(() => {
        localStorage.setItem('viken_skog.int.hiddenBirthdays', JSON.stringify(hiddenBirthdays))
        localStorage.setItem('viken_skog.int.hiddenNotifications', JSON.stringify(hiddenNotifications))
    }, [hiddenBirthdays, hiddenNotifications])

    useEffect(() => {
        if (hiddenNotifications.length || hiddenBirthdays.length){
            setShowRedDot(true);
        }
        else {
            setShowRedDot(false);
        }
    },[showRedDot, hiddenNotifications, hiddenBirthdays]);

    const clearHidden = () => {
        setHiddenBirthdays([])
        setHiddenNotifications([])
    }
    
    const now = moment()
        //const now = moment('2022-02-02 06:48:22')

    const setRandomMessage = (array) => {
        return setStatus(array[Math.floor(Math.random() * array.length)])
    }

    const setStatusMessage = () => {
        
        const fem = moment(now).hour('5').minute('0').second('0');
        const halvaatte =  moment(now).hour('7').minute('30').second('0');
        const ni = moment(now).hour('9').minute('0').second('0');
        const halvelleve = moment(now).hour('10').minute('30').second('0');
        const elleve = moment(now).hour('11').minute('0').second('0');
        const tolv = moment(now).hour('12').minute('0').second('0');
        const tretten = moment(now).hour('13').minute('0').second('0');
        const sytten = moment(now).hour('17').minute('0').second('0');
        const nitten = moment(now).hour('19').minute('0').second('0');
        const tjuefire = moment(now).hour('24').minute('0').second('0');
        const fire = moment(now).hour('16').minute('0').second('0');

        //Helg
        if(now.day() === 7 || now.day() === 6)return setRandomMessage(["GOD VIKEN"]);
        if(now.day() === 5 && now.isAfter(fire)) return setRandomMessage(["GOD VIKEN"]);
        
        //Tidspunkter i uka
        if(now.isBetween(fem, halvaatte)) return setRandomMessage(["Tidlig på! ☕", "Morgenfugl du! 🐓"]);
        if(now.isBetween(halvaatte, ni)) return setRandomMessage(["God morgen! 🌞", "Velkommen på jobb! 👷‍♂️", "Kaffetørst? 🍵"]);
        if(now.isBetween(halvelleve, tolv)) return setRandomMessage(["Snart lunsj! 🍌"]);
        if(now.isBetween(tolv, tretten)) return setRandomMessage(["Husk mat! 🍜", "Spist lunsj? 🤔", "Midt-på-dagen-vibber! 🌞"]);
        if(now.isBetween(sytten, nitten)) return setRandomMessage(["God ettermiddag! 🍽️"]);
        if(now.isBetween(tjuefire, fem)) return setRandomMessage(["God natt! 🛌🏽"]);

        //Random ellers utenom tidene over
        return setRandomMessage(["Tenk tre! 🌲", "God arbeidsøkt! 💪", "Kompetanse som lønner seg! 💰", "Husk nytenking! 🤔", "Ha en fin dag! 👍"]);
    }

    const cleanUpRedDot = ({notifications, birthdays}) => {
        // Check if hiddenNotifications exists in notifications, if not, remove it from the hiddenNotifications
        let hiddenNotifications = JSON.parse(localStorage.getItem('viken_skog.int.hiddenNotifications'));
        let hiddenBirthdays = JSON.parse(localStorage.getItem('viken_skog.int.hiddenBirthdays'));
        
        if (!notifications.length) setHiddenNotifications([]);
        if (!birthdays.length) setHiddenBirthdays([]);

        if(notifications.length && hiddenNotifications.length){
            let newHiddenNotifications = [];
            hiddenNotifications.map((mig) => {
                let result = notifications.find(el => {
                    return el.id === mig
                })
                if(result && result.id) newHiddenNotifications.push(result.id);
            })
            setHiddenNotifications(newHiddenNotifications);
        }

        if(birthdays.length && hiddenBirthdays.length){
            let newHiddenBirthdays = [];
            hiddenBirthdays.map((mig) => {
                let result = birthdays.find(el => {
                    return el.id === mig
                })
                if(result && result.id) newHiddenBirthdays.push(result.id);
            })
            setHiddenBirthdays(newHiddenBirthdays);
        }
        
    }

    const getNotifications = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getNotifications',
            ...user
        })
        return data;
    }

    useEffect(() => {
        setStatusMessage();

        getNotifications()
        .then(async (data) => {
            setNotifications(data.notifications)
            setBirthdays(data.birthdays)
            return {
                notifications: data.notifications, 
                birthdays: data.birthdays
            }
        })
        .then((data) => {
            cleanUpRedDot(data)
        });
    }, [])

    const Birthday = ({id, displayName, givenName, image}) => {
        
        
        const hide = () => {
            setHiddenBirthdays([...hiddenBirthdays, id])
        }
        

        if(hiddenBirthdays.includes(id)) return <div></div>
        return (
            <div className="col-12 notification-item">  
                <div className="bg-success-opacity p-3 rounded position-relative">
                    <div className="row no-gutters align-items-center">
                        <div className="col-auto ">
                            <div className="pr-2 notification-bar-image mr-3">
                                <img src={image} className="rounded" alt="#" />
                            </div>
                        </div>
                        <div className="col">
                            <p className="mb-0 opacity-75 small d-none d-md-block">{displayName} har bursdag i dag</p>
                            <h5 className="mb-0">Husk å gratulere {givenName} med dagen!</h5>
                        </div>
                        <div className="position-absolute toggle-remove" style={{top:0, right:'1rem', top: '1rem'}} onClick={() => hide()}>
                            <FontAwesomeIcon icon={['fal', 'xmark']} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    

    const Notification = ({ id, title, content }) => {

        const hide = () => {
            setHiddenNotifications([...hiddenNotifications, id])
        }

        if(hiddenNotifications.includes(id)) return <div></div>

        return (
            <div className="col-12 notification-item mb-3">  
                <div className="bg-danger-opacity p-3 rounded position-relative">
                    <div className="row no-gutters align-items-center">
                        <div className="col-auto">
                            <div className="notification-icon-small pr-2 pl-0 mr-3">
                                <span className='square-icon-wrapper-small bg-danger'>
                                    <FontAwesomeIcon icon={['fal', 'bell-exclamation']} size="2x" />
                                </span>
                            </div>
                        </div>
                        <div className="col">
                            <h5 className="mb-0">{title}</h5>
                            <p className="d-none d-md-block small mb-0">{content}</p>   
                        </div>
                        <div className="position-absolute toggle-remove" style={{top:0, right:'1rem', top: '1rem'}} onClick={() => hide()}>
                            <FontAwesomeIcon icon={['fal', 'xmark']} />
                        </div>
                    </div>
                </div>
            </div>
        )

        
    }

    

    return (
        <header id="header" className="py-3">
            <section>
                <div className="container">
                    <div className="row py-3 align-items-end">
                        <div className="col">
                            <div className="d-flex flex-row">
                                <div className="item-1">
                                    <img src={process.env.PUBLIC_URL + "/gfx/tre-test.svg"} alt="logo" className="pr-3" />
                                </div>
                                <div className="item-2 align-self-end">
                                    {
                                        statusMessage ? (
                                            <>
                                                <p className="mb-0 small text-uppercase opacity-5">{now.format('dddd D. MMMM')}</p>
                                                <h1 className="my-0 header-title">Hei, {user && user.givenName}. {status} </h1>
                                            </>
                                        ) :
                                        <p className="mb-0 fs-2 homelink"><Link to="/"><FontAwesomeIcon icon={['fal', 'arrow-left-long']} className="pl-2" /> <strong>Hjem</strong></Link></p>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="d-flex no-gutters align-items-center">
                            
                                <div className="col-auto">
                                    <span id="toggle-darkmode" className="p-2 mx-1 d-inline-block menuicon" onClick={() => toggleDarkMode()}>
                                        {!darkmode && <FontAwesomeIcon icon={['fal', 'moon-stars']} size="lg" />}
                                        {darkmode && <FontAwesomeIcon icon={['fal', 'sun']} size="lg" />}
                                    </span>
                                </div>
                                <div className="col-auto">
                                    <span id="show-search" className="p-2 mx-1 d-xl-inline-block d-none menuicon" onClick={() => setShowSearch(!showSearch)} ref={searchBtnRef}>
                                        { !showSearch ? 
                                        <span className="toggleicon d-block">
                                            <FontAwesomeIcon icon={['fal', 'magnifying-glass']} size="lg" />
                                        </span>
                                        :
                                        <span className="toggleicon shown position-relative">
                                            <FontAwesomeIcon icon={['fal', 'magnifying-glass']} size="lg"/>
                                            <FontAwesomeIcon icon={['fas', 'times']} size="lg" className='close' />
                                        </span>
                                        }
                                    </span>
                                </div>
                                <div className="col-auto">
                                    <span id="show-notifications" className={"p-2 mx-1 d-inline-block menuicon" + (!showNotifications ? ' hiddenstuff1' : '' ) + (showRedDot ? ' hiddenstuff' : '' )} onClick={() => clearHidden() } >
                                        <FontAwesomeIcon icon={['fal', 'bell-exclamation']} size="lg"/>
                                    </span>
                                </div>
                                <div className="col-auto"> 
                                    { image ? <img className="profile-image ml-2" src={image} alt="profile-img" /> : 
                                        <span className="fa-layers fa-stack">
                                            <FontAwesomeIcon icon={['fas', 'circle']}  className="text-light" size='3x' />
                                            <FontAwesomeIcon icon={['fal', 'user']} fixedWidth className="text-dark text-center" size='lg' style={{paddingLeft: 8}} />
                                      </span>
                                    }                 
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <Search showSearch={showSearch} setShowSearch={setShowSearch} searchBtnRef={searchBtnRef} />
                
                <div id="notification-bars">
                    
                    <div className="container">
                        <hr />
                        <div className="row notification-bar">
                            {
                                notifications.map((item, key) => {
                                    return <Notification key={key} {...item} />
                                })
                            }
                            {
                                birthdays.map((item, key) => {
                                    return <Birthday key={key} {...item} />
                                })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </header>
    )
}

