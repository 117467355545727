import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import axios from "axios"
import { createRef, useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AccessContext } from "../providers/AccessProvider"

export default function Search({ showSearch, setShowSearch, searchBtnRef }){

    const { apiUrl, baseUrl } = useContext(AccessContext)

    const [search, setSearch] = useState('')
    const [searchResult, setSearchResult] = useState(null)
    const [loading, setLoading] = useState(false)

    const searchRef = createRef()
    const containerRef = createRef()

    const fetchSearch = async () => {
        if(search.length){
            const { data } = await axios.post(apiUrl, {
                action: 'int-search',
                searchString: search
            })
            setLoading(false)
            setSearchResult(data.data)
        }
    }

    useEffect(() => {
        
        if(showSearch){
            if(searchRef && searchRef.current){
                searchRef.current.focus()
            }
        }
    }, [showSearch])


    useEffect(() => {
        
        
        const delayDebounceFn = setTimeout(() => {
            fetchSearch()
        }, 1000)
        
        
        if(search.length){
            setLoading(true)
            return () => clearTimeout(delayDebounceFn)
        }else{
            setLoading(false)
            setSearchResult(null)
        }
        
    }, [search])


    const ArticleSearchItem = ({ pagetitle, image, category, alias }) => {
        const { label } = category
        return (
            <Link to={`artikler/${alias}`}>
                <div className={"d-flex no-gutters align-items-center py-1"}>
                    <div className="col-auto pr-3">
                        <div className="img-wrapper">
                            {image ? 
                            <img src={baseUrl + image} className="h-100 w-100" alt="#" style={{objectFit: 'cover'}} /> :
                            <div className={"square-icon-wrapper bg-celebrate"}>
                                <FontAwesomeIcon icon={['fal', 'file']} size="lg" />
                            </div>
                            }
                        </div>
                    </div>
                    <div className="col">
                        <p className="text-muted mb-0 small">{label}</p>
                        <p className="font-weight-bold mb-0 sidebar-title text-dark">{pagetitle}</p>
                    </div>
                </div>
            </Link>
        )
    }

    function useOnClickOutside(ref, handler) {
        useEffect(
          () => {
            const listener = (event) => {
              // Do nothing if clicking ref's element or descendent elements
              if (!ref.current || ref.current.contains(event.target) || !searchBtnRef.current || searchBtnRef.current.contains(event.target)) {
                return;
              }
              handler(event);
            };
            document.addEventListener("mousedown", listener);
            document.addEventListener("touchstart", listener);
            return () => {
              document.removeEventListener("mousedown", listener);
              document.removeEventListener("touchstart", listener);
            };
          },
          // Add ref and handler to effect dependencies
          // It's worth noting that because passed in handler is a new ...
          // ... function on every render that will cause this effect ...
          // ... callback/cleanup to run every render. It's not a big deal ...
          // ... but to optimize you can wrap handler in useCallback before ...
          // ... passing it into this hook.
          [ref, handler]
        );
    }

    useOnClickOutside(containerRef, () => setShowSearch(false))

    if(!showSearch) return <div></div>

    

    return (
        
        <div className="container" ref={containerRef}>
            <div className="searchbar d-block position-relative mt-3">
                
                    <div className="input-container">
                        <div className="row no-gutters">
                            <div className="col-auto">
                                <FontAwesomeIcon icon={['fal', 'magnifying-glass']} style={{color: 'rgba(5,28,38, .5)'}} />
                            </div>
                            <div className="col">
                                <input ref={searchRef} value={search} onChange={(e) => setSearch(e.target.value)} type="search" className="w-100 pl-3" placeholder="Tema, applikasjon, artikkel" />
                            </div>
                        </div>
                        {
                            loading ? <div className="py-2 text-center"><FontAwesomeIcon icon={['fad', 'spinner-third']} size="2x" spin className="text-dark" /></div> : null
                        }
                        {
                            searchResult && searchResult.length ?
                            (
                                <div className="search-result-container">
                                    {
                                        searchResult.map((item, key) => {
                                            return <ArticleSearchItem key={key} {...item} />
                                        })
                                    }
                                </div>
                            )
                            : null
                            
                        }
                        {
                            searchResult && search.length && searchResult.length === 0 && !loading ? <div className="py-2 text-center"><h4 className="text-dark">Søket ga ingen treff 😔</h4></div> : null
                        }
                    </div>
            </div>
        </div>
        
    )
}