import React from 'react';
import { createRef, useContext, useEffect, useState } from 'react';
import { AccessContext } from "../providers/AccessProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment'
import { Link } from "react-router-dom";
import AddToCalendar from './AddToCalendar'




const EventBadge = (props) => { 

    const { topStyle, leftStyle, event, addedClass } = props;

    let dateFrom, dateTo;
 
    let dateDay, dateMonth, dateDayOfWeek;

    let externalUrl, url, alias, title, label;


    if (event) {
        dateDay = moment(event.start.toString()).format('D');
        dateMonth = moment(event.start.toString()).format('MMMM').substring(0, 3);
        dateDayOfWeek = moment(event.start.toString()).format('dddd').substring(0, 3);

        dateFrom = event.start.toString();
        dateTo = event.end.toString();
        externalUrl = event.externalUrl;
        url =  event.url;
        alias = event.alias;
        title = event.title;
        label = event.label;
    }

    return (               
        <div className={`eventBadge shadow p-3 ${addedClass}`} style={{top: topStyle, left: leftStyle}}>
            <div className="d-flex">
                <div className="date-badge position-static">
                    <div className="text-uppercase date-badge-small">{dateMonth}</div>
                    <h4 className="mb-0">{dateDay}</h4>
                    <div className="text-uppercase text-muted date-badge-small">{dateDayOfWeek}</div>
                </div>
                <div className="pl-4">
                    <div className="card-title"><p className="text-muted small mb-0"> {label} </p></div>
                    <div className="card-text"><h6>{title}</h6></div>
                </div>
                
            </div>
            <div className="d-flex justify-content-between align-items-end mt-3">
                <AddToCalendar
                    dateFrom={dateFrom} dateTo={dateTo} description={title}
                />
                {externalUrl ? (
                    url ? (
                        <a className="btn btn-info text-white rounded-pill mt-md-2 mt-1" target="_blank" rel="noopener noreferrer" href={url}>
                            <p className="small mb-0"> Les mer <span className="ml-1"><FontAwesomeIcon icon="fa-regular fa-arrow-up-right-from-square" /></span>  </p>
                        </a>
                    ) : null
                ) : (
                    url ? (
                        <Link className="btn btn-info text-white rounded-pill mt-md-2 mt-1" to={url}>
                            <p className="small mb-0"> Les mer </p>
                        </Link>
                    ) : null
                    
                )}
            </div>
        </div>
    )
}

export default EventBadge;