import React, { useState, useEffect, useRef } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import 'moment/locale/nb'; // Import the Norwegian locale for Bokmål
import EventBadge from './EventBadge'
import { event } from 'jquery';


moment.locale('nb'); // 'nb' is for Norwegian Bokmål. You can also use 'nn' for Nynorsk.
// Setup the localizer by providing the moment (or globalize, or Luxon) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

const messages = {
    allDay: 'Hele dagen',
    previous: 'Forrige',
    next: 'Neste',
    today: 'I dag',
    month: 'Måned',
    week: 'Uke',
    day: 'Dag',
    agenda: 'Agenda',
    date: 'Dato',
    time: 'Tid',
    event: 'Hendelse',
    noEventsInRange: 'Ingen hendelser i dette tidsrommet.',
    showMore: total => `+ Se mer (${total})`
};


const MyCalendar = (props) => {

    const slides = props.slides;
        
    const [selectedElement, setSelectedElement] = useState(null);
    const [addedElement, setAddedElement] = useState(null);
    const [positionStyles, setPositionStyles] = useState({ top: '', left: '' });
    const [selectedEvent, setSelectedEvent] = useState(null); // State for storing selected event data    
    const [addedClass, setAddedClass] = useState(''); // State for storing the class name

    const calendarRef = useRef(null);
        
    const myEventsList = slides.map(slide => ({
        title: slide.pagetitle,
        start: new Date(slide.dates.from),
        end: slide.dates.to ? new Date(slide.dates.to) : new Date(slide.dates.from),
        alias: slide.alias,
        url: slide.url,
        externalUrl: slide.external_url,
        label: slide.category.label
    }));

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectedElement && !selectedElement.contains(event.target)) {
                if (addedElement) {
                    addedElement.remove();
                    setAddedElement(null);
                }
                setAddedClass(''); // Reset class when clicking outside
                setSelectedElement(null)
                setPositionStyles({
                    top: '',
                    left: ''
                });
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [selectedElement, addedElement]);
        
    useEffect(() => {
        if (selectedElement) {            
                      
            const calendarElement = document.querySelector('.calendar-wrap');

            const calendarRect = calendarElement.getBoundingClientRect();

            const eventRow = selectedElement.getBoundingClientRect();

            const eventBadge = document.querySelector('.eventBadge');

            const eventBadgeRect = eventBadge.getBoundingClientRect();
        
            const distanceFromCalendarTop = eventRow.top - calendarRect.top;
            const distanceFromCalendarLeft = eventRow.left - calendarRect.left;

            let distanceFromCalendarLeftOver;
            

            // if eventBadge go out of the visible part of the browser window
            
            if (window.innerWidth >= 1024) {
                
                if((distanceFromCalendarLeft + eventBadgeRect.width) > calendarRect.width) {

                    distanceFromCalendarLeftOver = calendarRect.width - eventBadgeRect.width;
                
                    setPositionStyles({
                        top: `${distanceFromCalendarTop}px`,
                        left: `${distanceFromCalendarLeftOver}px`
                    });
    
                } else {
                    
                    setPositionStyles({
                        top: `${distanceFromCalendarTop}px`,
                        left: `${distanceFromCalendarLeft}px`
                    });
                }

            } else {
                setPositionStyles({
                    top: `50%`,
                    left: `50%`
                });
            }

            setAddedClass('active');
        }
    }, [selectedElement]);

    const handleSelectEvent = (event, e) => {
        const element = e.target;
        setSelectedElement(element);
        setSelectedEvent(event);        
    };

    return(
        <div className='calendar-wrap position-realtive p-lg-4 p-3'>
            <Calendar
                localizer={localizer}
                events={myEventsList}
                startAccessor="start"
                endAccessor="end"                
                onSelectEvent={(event, e) => handleSelectEvent(event, e)}        
                culture="nb"
                messages={messages}
            />
            <EventBadge topStyle={positionStyles.top} leftStyle={positionStyles.left}
                event={selectedEvent} addedClass={addedClass}
               />
        </div>
    )
}

export default MyCalendar;