import React from 'react';
import { createRef, useContext, useEffect, useState, useRef } from 'react';
import axios from 'axios'
import { AccessContext } from '../providers/AccessProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Swiper, SwiperSlide, useSwiper  } from 'swiper/react';
import { Scrollbar, FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/free-mode';
import mixitup from 'mixitup';
import mixitupMultifilter from '../vendor/mixitup-multifilter';
import MyCalendar from './MyCalendar';
import ActivitiesSwiper from './ActivitiesSwiper';
import AddToCalendar from './AddToCalendar'

mixitup.use(mixitupMultifilter);


const Activities = () => {

    const [isChecked, setIsChecked] = useState(false);
    const [slides, setSlids] = useState([])
    const [dataLoaded, setDataLoaded] = useState(false);

    const { baseUrl, apiUrl } = useContext(AccessContext)

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    }

    const fetchEvents = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getLatestEvents',
            limit: 50,
            offset: 0
        })


        if(data.status === 1 && Array.isArray(data.data) && data.data.length > 0) {
            console.log(data)
            setSlids(data.data)
            setDataLoaded(true)
        }else{
            fetchEvents([])
        }
    }

    useEffect(() => {
        fetchEvents()
    }, [])

    return (
        dataLoaded ? (
            <section className={isChecked ? "overflow-visible" : "overflow-hidden"}>
                <div className="container py-3 py-lg-4">
                    <div className="row no-gutters events-control justify-content-between">
                        <div className="col-xl-4 col-lg-7 col-6 order-xl-1 d-flex align-items-center">
                            <h3 className="ml-lg-4 mb-0 events-control_title" style={{ fontWeight: 900 }}>
                                Aktiviteter og frister
                            </h3>
                        </div>
                        <div className="col-6 col-xl-2 col-lg-4 mr-lg-4 d-flex align-items-center justify-content-end order-xl-3">
                            <label className="mr-lg-3 mr-2 mb-0 text-muted font-weight-normal label-switch" htmlFor="s1">
                                Kalendervisning
                            </label>
                            <input 
                                id="s1" 
                                type="checkbox" 
                                className="switch" 
                                checked={isChecked} 
                                onChange={handleCheckboxChange} 
                            />
                        </div>
                        <div className="mt-4 mt-xl-0 col-12 col-xl-5 col-lg-12 mixitup-controls d-lg-flex justify-content-xl-end justify-content-between order-xl-2">
                            {!isChecked && (
                                <fieldset data-filter-group className="mx-lg-4 d-flex justify-content-between align-items-center row no-gutters w-100">
                                    <div className="col-6 col-sm-7 col-md-8 col-xl-7 pr-2">
                                        <div className="d-flex align-items-center px-3 py-1 mr-lg-2 search-filter">
                                            <FontAwesomeIcon icon="fa-light fa-magnifying-glass" style={{ color: "#828e93" }} />
                                            <input 
                                                className="ml-2 w-100 font-weight-normal" 
                                                type="text" 
                                                id="activities-search" 
                                                data-search-attribute="data-search" 
                                                placeholder="Søk i aktiviteter" 
                                            />
                                        </div>
                                    </div>
                                    <div className="col-6 col-sm-5 col-md-4 col-xl-5 pl-2">
                                        <div className="d-flex justify-content-end select-filter">
                                            <select className="pl-3 pr-5 py-1 w-100 font-weight-normal">
                                                <option value="all">Vis alle kategorier</option>
                                                <option value=".Aktivitet">Aktiviteter</option>
                                                <option value=".Frist">Frister</option>
                                            </select>
                                            <div style={{position:"absolute", fontSize: "16px", right:"14px", top: "50%", transform:"translateY(-50%)", pointerEvents: "none"}}>
                                                <FontAwesomeIcon icon="fa-regular fa-chevron-down" style={{ color: "#fff" }} />
                                            </div>
                                                                              
                                        </div>
                                    </div>
                                </fieldset>
                            )}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 mt-4">
                            {isChecked ? (
                                <MyCalendar slides={slides} />
                            ) : (
                                <ActivitiesSwiper slides={slides} dataLoaded={dataLoaded} />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        ) : null
    );
}
export default Activities;