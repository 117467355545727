import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { AccessContext } from "../providers/AccessProvider"
import moment from 'moment'

export default function InternalCards(){

    const [articles, setArticles] = useState(null)


    const { baseUrl, apiUrl } = useContext(AccessContext)

    const fetchArticles = async () => {
        const { data } = await axios.post(apiUrl, {
            action: 'int-getLastestVikenNews'
        })

        

        if(data.status === 1){
            setArticles(data.data)
        }else{
            setArticles([])
        }
    }

    useEffect(() => {
        fetchArticles()
    }, [])

    

    const Article = ({ pagetitle, alias, image, editedon, createdon, url }) => {
        
        const formatDate = () => {
            const now = moment()
            const date = moment(editedon)
            const diff = now.diff(date, 'days')

        
            if(diff === 0) return 'i dag'
            if(diff === 1) return `i går`
            if(diff < 7) return `for ${diff} dager siden` 
        }
    

        return (
            <div className="col-12 col-lg-4 py-3">
                <div className="card h-100 shadow">
                    <a href={url} target="_blank">
                        <figure className="d-block m-0">
                            <img className="card-img-top object-fit-cover w-100" src={image ? baseUrl + image : process.env.PUBLIC_URL + '/images/cropped-swiper-img.jpg'} alt="#" />
                        </figure>
                        <div className="card-body ">
                            <h5 className="card-title ">{pagetitle}</h5>
                            <p className="card-text"><small className="text-muted">Oppdatert {formatDate()} </small></p>
                        </div>
                    </a>
                </div>
            </div>
        )
    }

    return (
        <section>
            <div className="container py-3 py-lg-4">
                <div className="row">
                    {
                        articles && articles.map((item, key) => {
                            return <Article key={key} {...item} />
                        })
                    }
                    <div className="col-12">
                        <div className="text-center">
                            <a href="https://www.viken.skog.no/aktuelt/artikler/" target="_blank">Flere saker fra viken.skog.no</a>
                        </div>
                    </div>
                </div>
            </div>   
        </section>
    )
}