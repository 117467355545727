import React from 'react';
import { createRef, useContext, useEffect, useState } from 'react';
import { AccessContext } from "../providers/AccessProvider";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment'
import { Link } from "react-router-dom";
import AddToCalendar from './AddToCalendar'




const ActivitySldie = ({ image, title, description, filter, alias, externalUrl, url, dateFrom, dateTo }) => { 

    const { baseUrl, apiUrl, darkmode } = useContext(AccessContext);
    let dateDay = moment(dateFrom).format('D');    
    let dateMonth = moment(dateFrom).format('MMMM').substring(0, 3); 
    let dateDayOfWeek = moment(dateFrom).format('dddd').substring(0, 3);

    return (               
        <div className={`card position-relative shadow ${filter}`}>
            <div className="date-badge">
                <div className="text-uppercase date-badge-small">{dateMonth}</div>
                <h4 className="mb-0">{dateDay}</h4>
                <div className="text-uppercase text-muted date-badge-small">{dateDayOfWeek}</div>
            </div>
            <img src={baseUrl + image} alt={ title } className="card-img-top w-100" />
            <div className="card-body">
                <div className="row h-100 align-content-between">
                    <div className="col-md-7">
                        
                        <div className="card-title">
                            <p className="text-muted small mb-0"> { title } </p>
                        </div>
                        <div className="card-text">
                            <h6>{ description }</h6>
                        </div>
                    </div>
                    <div className="col-md-5 d-flex flex-md-column justify-content-between pl-md-0">

                        <AddToCalendar
                            dateFrom={dateFrom} dateTo={dateTo} description={description}
                        />
                        {externalUrl ? (
                            url ? (
                                <a className="btn btn-info text-white rounded-pill mt-md-2 mt-1" target="_blank" rel="noopener noreferrer" href={url}>
                                    <p className="small mb-0"> Les mer <span className="ml-1"><FontAwesomeIcon icon="fa-regular fa-arrow-up-right-from-square" /></span>  </p>
                                </a>
                            ) : null
                        ) : (
                            url ? (
                                <Link className="btn btn-info text-white rounded-pill mt-md-2 mt-1" to={url}>
                                    <p className="small mb-0"> Les mer </p>
                                </Link>
                            ) : null
                            
                        )}
                    </div>
                </div>                                
            </div>
        </div>
    )
}

export default ActivitySldie;